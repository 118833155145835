import { ServerResponse } from 'http'

interface Redirect {
  to: string;
  code?: number;
}

export async function handleRedirect({
  code, to,
}: Redirect, res?: ServerResponse): Promise<boolean> {
  if (res) {
    await res.writeHead(code || 301, {
      Location: to,
    })
    await res.end()
    return true
  }

  location.assign(to)

  return true
}
